<template>
  <div class="sport-events-widget">
    <tab-simple tagName="span" :items="tabs" v-model:value="currentTab" />

    <slider-nav-sport-cat :current-category="currentCategory" :items="categories" @clickCategory="changeCategory" />

    <div class="sports-widget">
      <div id="altenar-top-events-widget" v-show="currentTab === 'topEvents'" />
      <div id="altenar-live-sport-widget" v-show="currentTab === 'liveSport'" />
    </div>
  </div>
</template>
<script setup lang="ts">
  import type { ISportCategory } from '@skeleton/core/types';

  const altenarStore = useAltenarStore();
  const { addTopEventsCarouselWidget, removeTopEventsCarouselWidget, updateTopEventsCarouselWidget } = altenarStore;
  import { sportsList } from '@skeleton/core/consts/sportsConfig';
  import type { IHomePage } from '~/types';
  const { getContent } = useProjectMethods();

  const props = defineProps<{
    currentLocaleData: Maybe<IHomePage>;
    defaultLocaleData: Maybe<IHomePage>;
  }>();

  const currentCategory = ref('all');
  const currentTab = ref('topEvents');

  const categories = computed(() => {
    return [
      {
        label: getContent(props.currentLocaleData, props.defaultLocaleData, 'sportCategories.all'),
        name: 'all',
      },
      {
        label: getContent(props.currentLocaleData, props.defaultLocaleData, 'sportCategories.football'),
        name: 'football',
        icon: 'football',
        sportId: getSportIdByCode('soccer'),
      },
      {
        label: getContent(props.currentLocaleData, props.defaultLocaleData, 'sportCategories.basketball'),
        name: 'basketball',
        icon: 'basketball',
        sportId: getSportIdByCode('basketball'),
      },
      {
        label: getContent(props.currentLocaleData, props.defaultLocaleData, 'sportCategories.tennis'),
        name: 'tennis',
        icon: 'tennis',
        sportId: getSportIdByCode('tennis'),
      },
      {
        label: getContent(props.currentLocaleData, props.defaultLocaleData, 'sportCategories.iceHockey'),
        name: 'ice-hockey',
        icon: 'puck',
        sportId: getSportIdByCode('ice-hockey'),
      },
      {
        label: getContent(props.currentLocaleData, props.defaultLocaleData, 'sportCategories.volleyball'),
        name: 'volleyball',
        icon: 'volleyball',
        sportId: getSportIdByCode('volleyball'),
      },
      {
        label: getContent(props.currentLocaleData, props.defaultLocaleData, 'sportCategories.handball'),
        name: 'handball',
        icon: 'handball',
        sportId: getSportIdByCode('handball'),
      },
      {
        label: getContent(props.currentLocaleData, props.defaultLocaleData, 'sportCategories.eSportsPlus'),
        name: 'eSportsPlus',
        icon: 'controller',
        sportId: getSportIdByCode('e-sports+'),
      },
    ];
  });

  const tabs = computed(() => {
    return [
      {
        name: getContent(props.currentLocaleData, props.defaultLocaleData, 'sportTabs.topEvents'),
        id: 'topEvents',
      },
      //Еще в процессе
      // {
      //   name: getContent(props.currentLocaleData, props.defaultLocaleData, 'sportTabs.liveSport'),
      //   id: "liveSport",
      //   icon: "live"
      // }
    ];
  });

  function getSportIdByCode(code: string): number | undefined {
    return sportsList.find(item => item.code === code)?.id;
  }

  const changeCategory = (category: ISportCategory) => {
    currentCategory.value = category.name;
    updateTopEventsCarouselWidget(category?.sportId);
  };

  onMounted(async () => {
    await addTopEventsCarouselWidget('altenar-top-events-widget');
  });

  onBeforeUnmount(() => {
    removeTopEventsCarouselWidget();
  });
</script>

<style src="~/assets/styles/components/group/sport-events-widget.scss" lang="scss" />
