<template>
  <div class="sport-card" @click="clickSport" :style="{backgroundImage: `url(${sportInfo?.background})`}" >

    <atomic-image class="sport-card__img" :src="sportInfo?.image" />
    <div class="sport-card__label">{{ sportInfo?.label }}</div>
  </div>
</template>

<script setup lang="ts">
  import type { ISportsItem } from '~/types';

  const props = defineProps<{
    sportInfo?: Maybe<ISportsItem>;
  }>();

  const router = useRouter();
  const profileStore = useProfileStore();
  const { isLoggedIn, isTelegramUser } = storeToRefs(profileStore);

  const globalStore = useGlobalStore();
  const { globalComponentsContent, defaultLocaleGlobalComponentsContent } = storeToRefs(globalStore);

  const { localizePath } = useProjectMethods();
  const { taptic } = useHaptics();

  const clickSport = async (): Promise<void> => {
    if (!props.sportInfo) return;

    taptic('soft');

    await router.replace(localizePath(`/sport#/sport/${props.sportInfo.id}/`));
  };
</script>

<style src="~/assets/styles/components/card/sport.scss" lang="scss" />
