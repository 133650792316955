<template>
  <div class="home">
    <main-slider v-if="filteredSlider.length" :slides="filteredSlider" />

    <bottom-sheet-global-search v-if="isMobile" />

    <div
      v-if="getContent(currentLocaleData, defaultLocaleData, 'categories', [])"
      class="card-category__container"
      :class="cardsModifier"
    >
      <card-category
        v-for="(item, itemIndex) in getContent(currentLocaleData, defaultLocaleData, 'categories', [])"
        :key="itemIndex"
        :cssClass="`${itemIndex}`"
        v-bind="item"
      />
    </div>

    <client-only>
      <list-pay-methods v-if="!isTelegramUser" />
    </client-only>

    <group-sport-events-widget :currentLocaleData="currentLocaleData" :defaultLocaleData="defaultLocaleData" />

    <div class="home-main">
      <group-sport-cards />

      <group-recently v-if="isLoggedIn" />

      <group-games-adaptive key="hot" showAllBtn showArrows :category="hotGamesCollection" />

      <group-winners />

      <group-games-adaptive key="all" showAllBtn showArrows :category="allGameCollection" />

      <group-providers show-arrows show-all-btn />

      <group-promotions />
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { IHomePage } from '~/types';
  import type { ICollection } from '@skeleton/core/types';

  import { ModalName } from '@skeleton/consts/modals';

  const route = useRoute();
  const router = useRouter();
  const dayjs = useDayjs();

  const { openModalSync } = useModalStore();
  const profileStore = useProfileStore();
  const { getContent } = useProjectMethods();
  const { setReferralId } = profileStore;
  const { isTelegramUser, isLoggedIn } = storeToRefs(profileStore);
  const deviceStore = useDeviceStore();
  const { isMobile } = storeToRefs(deviceStore);

  const { getContentData: getHomeContentData } = useContentLogic<IHomePage>({
    contentKey: 'homePageContent',
    contentRoute: ['pages', 'home'],
    isPage: true,
  });

  const { data: homeContent } = await useLazyAsyncData(getHomeContentData);

  const currentLocaleData = computed(() => homeContent.value?.currentLocaleData);
  const defaultLocaleData = computed(() => homeContent.value?.defaultLocaleData);

  const { getContentData: getSliderContentData } = useContentLogic<IHomePage>({
    contentKey: 'casinoPageContent',
    contentRoute: ['pages', 'casino'],
    isPage: false,
  });

  const { data: sliderContent } = await useLazyAsyncData(getSliderContentData);

  const { getCollectionsList, findGameCollectionByIdentity } = useGamesStore();
  const { data: gameCollections, pending: pendingGames } = await useLazyAsyncData(() => getCollectionsList(), {
    server: false,
  });

  const cardsModifier = computed(() => {
    const length =
      Object.keys(
        getContent(homeContent?.value?.currentLocaleData, homeContent?.value?.defaultLocaleData, 'categories')
      )?.length || 0;
    return length ? `has-${length}-cards` : '';
  });

  const filteredSlider = computed(() => {
    const sliders = getContent(
      sliderContent.value?.currentLocaleData,
      sliderContent.value?.defaultLocaleData,
      'slider',
      []
    );
    if (!sliders.length) {
      return [];
    }

    const { filteredSlider } = useSliderFilter({
      slider: sliders,
      isLoggedIn: isLoggedIn.value,
      isTelegramUser: isTelegramUser.value,
      sliderFilterTime: dayjs.utc().toString(),
    });

    return filteredSlider.value;
  });

  const hotGamesCollection = computed(() => getGameCollection('hot'));
  const allGameCollection = computed(() => getGameCollection('All'));

  const getGameCollection = (identity: string): ICollection | undefined => {
    return findGameCollectionByIdentity(gameCollections.value || [], identity);
  };

  const loadingStore = useLoadingStore();

  const loading = computed(() => {
    return !homeContent.value || !sliderContent.value || pendingGames.value;
  });

  watchEffect(() => {
    loadingStore.setLoadingState(loading.value);
  });

  onMounted(async () => {
    if (route.query.start) {
      setReferralId(route.query.start.toString().replace('ref_id-', ''));
      const { query } = useRoute();
      delete query.start;
      await router.replace({ query });
      await openModalSync(ModalName.SIGN_IN);
    }
  });
</script>

<style src="~/assets/styles/pages/index.scss" lang="scss" />
