<template>
  <div
    ref="sliderContainer"
    class="nav-cat-scroll slider"
    @mouseup="handleMouseUp"
    @mousedown="handleMouseDown"
    @mouseleave="handleMouseUp"
    @mousemove="handleMouseMove"
  >
    <div class="nav-cat-wrap" :style="{ transform: `translateX(${currentTranslateX}px)` }">
      <div class="nav-cat">
        <span
          v-for="(item, index) in items"
          :key="index"
          class="nav-cat-item"
          :data-index="index"
          :class="{
            'is-active': currentCategory === item.name,
            'is-no-icon': !item?.icon,
          }"
          @click="emit('clickCategory', item, isMoved)"
        >
          <atomic-icon :id="item?.icon" />
          <span>{{ item.label }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { ISportCategory } from '~/skeleton/core/types';

  const emit = defineEmits(['clickCategory']);
  const sliderContainer = ref(null);

  const { currentTranslateX, handleMouseDown, handleMouseMove, handleMouseUp, isMoved } = useSlider({
    sliderContainer,
  });

  defineProps<{
    items: ISportCategory[];
    currentCategory?: string;
  }>();

  watch(isMoved, async (newValue) => {
    if (newValue) {
      await nextTick();
      isMoved.value = false;
    }
  });
</script>

<style src="~/assets/styles/components/slider/nav/cat.scss" lang="scss" />
