<template>
  <div v-if="paymentsItems?.length" class="pay-systems">
    <div class="pay-systems__info">
      <div class="pay-systems__count">
        {{ paymentsItems.length }}
      </div>
      <div class="pay-systems__title">
        {{ title }}
      </div>
    </div>
    <div class="pay-systems__wrapper">
      <div ref="sliderNode" class="pay-systems__list">
        <div class="pay-systems__container">
          <div v-for="image in paymentsItems" :key="image" class="pay-systems__item">
            <atomic-image class="pay-systems__logo logo" :src="image" @click="paymentsItemClick" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  import emblaCarouselVue from 'embla-carousel-vue';
  import Autoplay from 'embla-carousel-autoplay';

  import { PAY_SYSTEMS } from '@skeleton/consts/method';
  import { ModalName } from '@skeleton/consts/modals';

  const profileStore = useProfileStore();
  const { isLoggedIn } = storeToRefs(profileStore);
  const { openWalletModal } = useTransactionStore();
  const {
    public: { gamehubCdn },
  } = useRuntimeConfig();
  const { openModalSync } = useModalStore();
  const colorMode = useColorMode();

  const autoplayOptions = {
    delay: 3500,
    stopOnInteraction: false,
    stopOnMouseEnter: true,
  };

  const [sliderNode] = emblaCarouselVue(
    {
      loop: true,
      align: 'start',
    },
    [Autoplay(autoplayOptions)]
  );

  const paymentsItemClick = () => {
    isLoggedIn.value ? openWalletModal('deposit') : openModalSync(ModalName.SIGN_IN);
  };

  const title = computed(() => {
    const text = 'payment methods';
    return text.split(' ').join('\n');
  });

  const paymentsItems = computed(() => {
    return Object.values(PAY_SYSTEMS).map(
      item => `${gamehubCdn}/pay-systems/${colorMode.value}/${formatPayMethodKey(item)}.svg`
    );
  });
</script>

<style src="~/assets/styles/components/list/pay-methods.scss" lang="scss" />
