<template>
  <div v-if="promotionsList?.length" class="group-promotions">
    <atomic-cat-heading :icon="globalComponentsContent?.promotions?.icon">
      {{ globalComponentsContent?.promotions?.label }}
    </atomic-cat-heading>

    <div class="group-promotions__list">
      <div
        v-for="(promotion, index) in promotionsList"
        :key="index"
        class="group-promotions__item"
        :class="{ hovered: hoverCard === index }"
        @click="clickCard(index)"
      >
        <img v-lazy-load  class="picture" :data-src="promotion.image" alt="" />
        <div class="content">
          <div class="title">{{ promotion.title }}</div>
          <div class="sub-title">{{ promotion.topLabel }}</div>
          <div class="actions">
            <button-base
              type="primary"
              size="md"
              @click="isLoggedIn ? openWalletModal('deposit') : openModalSync(ModalName.SIGN_UP)"
            >
              {{ promotion.buttonLabel }}
            </button-base>

            <atomic-link class="link-more" :href="promotion.link.url">
              {{ promotion.link.label }}
            </atomic-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { IWelcomeBonusesPage } from '~/types';

  import { ModalName } from '@skeleton/consts/modals';

  const globalStore = useGlobalStore();
  const { globalComponentsContent } = storeToRefs(globalStore);
  const profileStore = useProfileStore();
  const { isLoggedIn } = storeToRefs(profileStore);
  const { openWalletModal } = useTransactionStore();
  const { openModalSync } = useModalStore();
  const deviceStore = useDeviceStore();
  const { isMobile } = storeToRefs(deviceStore);

  const hoverCard = ref<number>();

  const clickCard = (index: number): void => {
    if (isMobile.value) {
      hoverCard.value = hoverCard.value === index ? undefined : index;
    }
  };

  const clickOutside = (e: MouseEvent): void => {
    if (e.target instanceof HTMLElement && e.target.closest('.group-promotions__item')) return;
    hoverCard.value = undefined;
  };

  const { getContent } = useProjectMethods();

  const { getContentData } = useContentLogic<IWelcomeBonusesPage>({
    contentKey: 'welcomePageContent',
    contentRoute: ['pages', 'welcome-bonuses'],
    isPage: false,
  });

  const { status, data: welcomeContent } = await useLazyAsyncData(getContentData);

  const howGetItems = computed(() => {
    const steps = getContent(
      welcomeContent?.value?.currentLocaleData,
      welcomeContent?.value?.defaultLocaleData,
      'howGet.steps'
    );
    return steps || [];
  });

  const promotionsList = computed<IWelcomeBonusesPage['welcome']['items'][]>(() => {
    return getContent(
      welcomeContent?.value?.currentLocaleData,
      welcomeContent?.value?.defaultLocaleData,
      'welcome.items'
    );
  });

  onMounted(() => {
    document.addEventListener('click', clickOutside);
  });

  onBeforeUnmount(() => {
    document.removeEventListener('click', clickOutside);
  });
</script>

<style src="~/assets/styles/components/group/promotions.scss" lang="scss" />
